<template>
  <div id="homepage-1">
    <div class="row ls-hero-banner-landing">
      <div class="col-12 ls-center">
        <img
          class=""
          style="width: 300px; height: auto"
          src="/img/logo.png"
          alt="Logo"
        />
        <h1 style="color: white; padding-bottom: 60px; font-size: 26px">
          One Stop Zimbabwe!
        </h1>
        <p
          style="
            color: white;
            padding-bottom: 15px;
            max-width: 70vw;
            margin: auto;
          "
        >
          Our new website is on its way
        </p>

        <p
          style="
            color: white;
            padding-bottom: 15px;
            max-width: 70vw;
            margin: auto;
          "
        >
          We are Pahukama/S`hlobo®️!
        </p>
        <p
          style="
            color: white;
            padding-bottom: 15px;
            max-width: 70vw;
            margin: auto;
          "
        >
          To launch very soon So please sit tight as we continue busying
          ourselves with final renovations
        </p>
        <p
          style="
            color: white;
            padding-bottom: 15px;
            max-width: 70vw;
            margin: auto;
          "
        >
          It’s a Pahukama Affair
        </p>
        <p
          style="
            color: white;
            padding-bottom: 15px;
            max-width: 70vw;
            margin: auto;
          "
        >
          Get Runners for any Errand or Task, Remit in Good and Services by
          buying for loved ones direct from Merchants , Pay Bills, Fees to any
          Merchant & Buy Airtime and Data Online
        </p>
      </div>
      <div class="col-12 ls-center"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Vue from "vue";
import ShopCategoryCard from "../../../components/Cards/ShopCategoryCard";
import ShopProductCard from "../../../components/ShopProductCard";
import HomeSlideshow from "../../../components/HomeSlideshow";
import SearchForm from "../../../components/SearchForm";
import HeaderSearch from "../../../components/HeaderSearch";
import HeaderSearch2 from "../../../components/HeaderSearch2";

export default {
  name: "Home",
  components: {
    SearchForm,
    HomeSlideshow,
    ShopProductCard,
    ShopCategoryCard,
    HeaderSearch,
    HeaderSearch2,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart,
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
  },
  mounted() {
    var that = this;
  },
  methods: {
    hasCategories(categories) {
      if (categories.length > 0) {
        return categories[0].length > 0;
      }
      return false;
    },
  },
};
</script>
<style lang="css" scoped>
/* @import url(/css/common12d2.css); */

.banner-heading {
  font-weight: 900;
  color: rgb(22, 76, 79);
  font-size: 29px;
  box-sizing: border-box;
  transition: all 400ms ease 0s;
  display: flex;
  flex-direction: column;
}
.banner-heading .banner-heading--1 {
}
.banner-heading .banner-heading--2 {
  font-weight: 300;
}
.banner-heading.banner-title {
  font-weight: 700;
  color: rgb(22, 76, 79);
  font-size: 12px;
  line-height: 1.3;
  box-sizing: border-box;
  margin-top: 0px;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  transition: all 400ms ease 0s;
}
.search-button {
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  min-width: 125px;
  color: #262626;
  max-height: 48px;
  font-size: 12px;
  padding: 8px 20px;
  margin-left: -25px;
}
.input-group-text {
  border-radius: 0px 2px;
  background-color: white;
  border-right: none;
}
.search-input {
  height: 48px;
  /* padding: 0 30px 0 48px; */
  border-left: none;
  border-right: none;
  min-width: 35%;
  clip-path: polygon(0% 0%, 98% 0%, 90% 100%, 0% 100%);
}

.card-frame > .card-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 5px;
}

.card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 5rem 6rem;
    background-color: #F1F1F1; */
}

@media screen and (max-width: 768px) {
  .card-section {
    padding: 3rem 3rem;
  }

  .search-input {
    width: 500px;
  }
}

@media screen and (max-width: 575px) {
  .card-section {
    padding: 0rem;
    padding-top: 4rem;
  }
  .search-input {
    width: 500px;
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .card-container {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 575px) {
  .card-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    row-gap: 0.8rem;
  }
}

.card-frame {
  height: 25rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

@media screen and (max-width: 575px) {
  .card-frame {
    height: 10rem;
  }
}

.card-frame[card="fast"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/fast_food.webp");
}

.card-frame[card="fast"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.6);
}

.card-frame[card="pharmacy"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/pharmacy.webp");
}

.card-frame[card="pharmacy"] > .card-overlay {
  background-color: rgba(60, 3, 70, 0.6);
}

.card-frame[card="electronics"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/electronics.webp");
}

.card-frame[card="electronics"] > .card-overlay {
  background-color: rgba(13, 70, 3, 0.6);
}

.card-frame[card="grocery"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/grocery.webp");
}

.card-frame[card="grocery"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hair_beauty"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hair_beauty.webp");
}

.card-frame[card="hair_beauty"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.5);
}

.card-frame[card="house_home"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/house.webp");
}

.card-frame[card="house_home"] > .card-overlay {
  background-color: rgba(38, 95, 109, 0.6);
}

.card-frame[card="construction"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/construction.webp");
}

.card-frame[card="construction"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hardware"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hardware.webp");
}

.card-frame[card="hardware"] > .card-overlay {
  background-color: rgba(6, 69, 153, 0.6);
}

.card-frame[card="vehicles"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/vehicles.webp");
}

.card-frame[card="vehicles"] > .card-overlay {
  background-color: rgba(211, 42, 42, 0.6);
}

.card-frame[card="solar"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/solar.webp");
}

.card-frame[card="solar"] > .card-overlay {
  background-color: rgba(23, 167, 131, 0.6);
}

.card-frame[card="entertainment"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/entertainment.webp");
}

.card-frame[card="entertainment"] > .card-overlay {
  background-color: rgba(238, 48, 136, 0.6);
}

.card-frame[card="funeral"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/funeral.webp");
}

.card-frame[card="funeral"] > .card-overlay {
  background-color: rgba(63, 68, 65, 0.6);
}

.card-frame[card="gifts"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/treats.webp");
}

.card-frame[card="gifts"] > .card-overlay {
  background-color: rgba(201, 58, 77, 0.6);
}

.card-heading {
  font-size: 1.7em;
  text-align: center;
  padding: 2.5rem 0;
  padding-top: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .card-heading {
    padding: 1.5rem 0;
  }
}

.card-title {
  color: #ffffff;
  font-size: 1.2em !important;
  margin: 0;
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 575px) {
  .card-title {
    font-size: 1em;
  }
}

.card-text {
  padding: 2rem;
  padding-right: 0.5rem;
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
}

.card-text > p {
  padding: 0;
  font-size: 0.96em;
  margin: auto 0;
  color: #fff;
}

@media screen and (max-width: 575px) {
  .card-text > p {
    display: none;
  }
}

.card-text > .btn-primary {
  padding: 10px;
  min-width: 130px;
  font-size: 15px;
}

@media screen and (max-width: 575px) {
  .card-text {
    flex: 1;
    padding: 0.7rem 1rem;
    justify-content: space-between;
  }
}

.card-logo {
  background-color: #fff;
  position: relative;
  flex: 1;
  -webkit-clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  height: 100%;
}

.card-logo > img {
  max-width: 60%;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

@media screen and (max-width: 575px) {
  .card-logo {
    display: none;
  }
}

.card-paragraph {
  padding: 0 !important;
}

.promo-runner {
  width: 100%;
  min-height: 6rem;
  display: flex;
  margin: 3rem 0;
  border-radius: 5px;
  padding: 2rem 3rem;
  padding-right: 4rem;
  align-items: center;
  justify-content: space-between;
  background-color: #ad6a00;
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .promo-runner {
    padding: 1.5rem 1rem;
    flex-direction: column;
    margin: 0.8rem 0;
  }
}

.promo-delivery {
  min-height: 15rem;
  margin: 3.5rem 0;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

.promo-delivery > .delivery-nav {
  width: 8%;
}

@media screen and (max-width: 575px) {
  .promo-delivery {
    margin: 0.8rem 0;
  }
}

.runner-text {
  margin: 0;
  margin-bottom: 0.4rem;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .runner-text {
    font-size: 1em;
  }
}

@media screen and (max-width: 575px) {
  .runner-text {
    font-size: 0.93em;
  }
}

.runner-info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.runner-button {
  display: inline-block;
  text-transform: uppercase;
  padding: 0.5rem 0.74rem;
  background-color: white;
  border-radius: 5px;
  color: #000;
}

.runner-button:hover {
  text-decoration: none;
}

@media screen and (max-width: 575px) {
  .runner-button {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
}

.runner-heading {
  margin-bottom: 1rem;
}

@media screen and (max-width: 575px) {
  .runner-heading {
    font-size: 1.1em;
    width: 100%;
  }
}

.promo-delivery {
  min-height: 15rem;
  margin: 3.5rem 0;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

.promo-delivery > .delivery-nav {
  width: 8%;
}

@media screen and (max-width: 575px) {
  .promo-delivery {
    margin: 0.8rem 0;
  }
}

.runner-text {
  margin: 0;
  margin-bottom: 0.4rem;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .runner-text {
    font-size: 1em;
  }
}

@media screen and (max-width: 575px) {
  .runner-text {
    font-size: 0.93em;
  }
}

.runner-info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.runner-button {
  display: inline-block;
  text-transform: uppercase;
  padding: 0.5rem 0.74rem;
  background-color: white;
  border-radius: 5px;
  color: #000;
}

.runner-button:hover {
  text-decoration: none;
}

@media screen and (max-width: 575px) {
  .runner-button {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
}

.runner-heading {
  margin-bottom: 1rem;
}

@media screen and (max-width: 575px) {
  .runner-heading {
    font-size: 1.1em;
    width: 100%;
  }
}

.delivery-item {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 35rem;
  border-radius: 5px;
  padding: 0 9%;
}

.delivery-item--1 {
  width: 100%;
  /* background-image: url(../images/delivery6efb.webp?5c9178764cdb23596f542cad14f283da); */
  background-position: bottom;
  padding-top: 3rem;
}

.delivery-item--1 .delivery-info {
  color: #000;
}

.delivery-item--1 .delivery-text {
  font-size: 1.4em;
}

.delivery-item--1 .delivery-heading {
  font-size: 1.4em;
  font-family: ClanPro-Medium, Tahoma, Geneva, Verdana, sans-serif;
}

.runner-info > h3,
.runner-info > h5 {
  color: #ffffff !important;
  font-size: 13px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
}
.runner-info > h3 {
  font-size: 15px;
  font-weight: 700;
}
.show-mobile {
  display: none;
}
@media screen and (max-width: 575px) {
  .promo-delivery {
    min-height: 15rem;
    margin: 3.5rem 0;
    border-radius: 5px;
    box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
  }

  .promo-delivery > .delivery-nav {
    width: 8%;
  }

  @media screen and (max-width: 575px) {
    .promo-delivery {
      margin: 0.8rem 0;
    }
  }

  .runner-text {
    margin: 0;
    margin-bottom: 0.4rem;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    .runner-text {
      font-size: 1em;
    }
  }

  @media screen and (max-width: 575px) {
    .runner-text {
      font-size: 0.93em;
    }
  }

  .runner-info {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .runner-button {
    display: inline-block;
    text-transform: uppercase;
    padding: 0.5rem 0.74rem;
    background-color: white;
    border-radius: 5px;
    color: #000;
  }

  .runner-button:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 575px) {
    .runner-button {
      width: 100%;
      text-align: center;
      margin-top: 1rem;
    }
  }

  .runner-heading {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 575px) {
    .runner-heading {
      font-size: 1.1em;
      width: 100%;
    }
  }

  .delivery-item {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px;
    padding: 0 9%;
  }

  .delivery-item--1 {
    width: 100%;
    /* background-image: url(../images/delivery6efb.webp?5c9178764cdb23596f542cad14f283da); */
    background-position: bottom;
    padding-top: 3rem;
  }

  .delivery-item--1 .delivery-info {
    color: #000;
  }

  .delivery-item--1 .delivery-text {
    font-size: 1.4em;
  }

  .delivery-item--1 .delivery-heading {
    font-size: 1.4em;
    font-family: ClanPro-Medium, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  }

  @media screen and (max-width: 575px) {
    .delivery-item--1 {
      padding-top: 28%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .delivery-item--2 {
    padding: 2rem 9%;
    background: #88348f;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 575px) {
    .delivery-item--2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .delivery-item--3 {
    background-color: #1885b0;
    padding: 1.2rem 9%;
  }

  .delivery-item--3 > .delivery-how--heading {
    color: white;
    font-weight: 900;
    font: 1.7em;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 575px) {
    .delivery-item--3 > .delivery-how--heading {
      font-size: 14px;
    }
  }

  .delivery-item--3 > .delivery-how--list {
    padding-top: 1rem;
    -moz-column-count: 2;
    -moz-column-rule: 2px solid white;
    -webkit-column-count: 2;
    -webkit-column-rule: 2px solid white;
    column-count: 2;
    -webkit-column-gap: 4rem;
    -moz-column-gap: 4rem;
    column-gap: 4rem;
    font-size: 1.24em;
    font-weight: 400;
    color: white;
    line-height: 1.5;
  }

  @media screen and (max-width: 768px) {
    .delivery-item--3 > .delivery-how--list {
      font-size: 1em;
    }
  }

  @media screen and (max-width: 575px) {
    .delivery-item--3 > .delivery-how--list {
      -moz-column-count: 1;
      -moz-column-gap: 0rem;
      -moz-column-rule: none;
      font-size: 14px;
      -webkit-column-count: 1;
      -webkit-column-rule: none;
      -webkit-column-gap: 0rem;
      column-count: 1;
      column-gap: 0rem;
    }
  }

  @media screen and (max-width: 575px) {
    .delivery-item {
      min-height: 45rem;
    }
  }

  .delivery-info {
    flex: 0 0 60%;
    padding: 0 0.3rem;
    display: flex;
    font-family: "ClanPro-Medium", "Segoe UI", Tahoma, Geneva, Verdana,
      sans-serif;
    flex-direction: column;
    color: white;
    width: 60%;
    align-items: flex-start;
    font-weight: 900;
  }

  @media screen and (max-width: 575px) {
    .delivery-info {
      width: 100%;
      padding: 0 0.5rem;
      align-items: flex-start;
      font-size: 14px;
      text-align: center;
    }
  }

  .delivery-heading {
    letter-spacing: 0.08em;
    font-size: 1.8em;
    margin-top: 1rem;
    margin-bottom: 2.3rem;
    .promo-delivery {
      min-height: 15rem;
      margin: 3.5rem 0;
      border-radius: 5px;
      box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
    }

    .promo-delivery > .delivery-nav {
      width: 8%;
    }

    @media screen and (max-width: 575px) {
      .promo-delivery {
        margin: 0.8rem 0;
      }
    }

    .runner-text {
      margin: 0;
      margin-bottom: 0.4rem;
      font-weight: 400;
    }

    @media screen and (max-width: 768px) {
      .runner-text {
        font-size: 1em;
      }
    }

    @media screen and (max-width: 575px) {
      .runner-text {
        font-size: 0.93em;
      }
    }

    .runner-info {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .runner-button {
      display: inline-block;
      text-transform: uppercase;
      padding: 0.5rem 0.74rem;
      background-color: white;
      border-radius: 5px;
      color: #000;
    }

    .runner-button:hover {
      text-decoration: none;
    }

    @media screen and (max-width: 575px) {
      .runner-button {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
      }
    }

    .runner-heading {
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 575px) {
      .runner-heading {
        font-size: 1.1em;
        width: 100%;
      }
    }

    .delivery-item {
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      min-height: 45rem;
      border-radius: 5px;
      padding: 0 9%;
    }

    .delivery-item--1 {
      width: 100%;
      /* background-image: url(../images/delivery6efb.webp?5c9178764cdb23596f542cad14f283da); */
      background-position: bottom;
      padding-top: 3rem;
    }

    .delivery-item--1 .delivery-info {
      color: #000;
    }

    .delivery-item--1 .delivery-text {
      font-size: 1.4em;
    }

    .delivery-item--1 .delivery-heading {
      font-size: 1.4em;
      font-family: ClanPro-Medium, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
    }

    @media screen and (max-width: 575px) {
      .delivery-item--1 {
        padding-top: 28%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .delivery-item--2 {
      padding: 2rem 9%;
      background: #88348f;
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 575px) {
      .delivery-item--2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .delivery-item--3 {
      background-color: #1885b0;
      padding: 1.2rem 9%;
    }

    .delivery-item--3 > .delivery-how--heading {
      color: white;
      font-weight: 900;
      font: 1.7em;
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: 575px) {
      .delivery-item--3 > .delivery-how--heading {
        font-size: 14px;
      }
    }

    .delivery-item--3 > .delivery-how--list {
      padding-top: 1rem;
      -moz-column-count: 2;
      -moz-column-rule: 2px solid white;
      -webkit-column-count: 2;
      -webkit-column-rule: 2px solid white;
      column-count: 2;
      -webkit-column-gap: 4rem;
      -moz-column-gap: 4rem;
      column-gap: 4rem;
      font-size: 1.24em;
      font-weight: 400;
      color: white;
      line-height: 1.5;
    }

    @media screen and (max-width: 768px) {
      .delivery-item--3 > .delivery-how--list {
        font-size: 1em;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-item--3 > .delivery-how--list {
        -moz-column-count: 1;
        -moz-column-gap: 0rem;
        -moz-column-rule: none;
        font-size: 14px;
        -webkit-column-count: 1;
        -webkit-column-rule: none;
        -webkit-column-gap: 0rem;
        column-count: 1;
        column-gap: 0rem;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-item {
        min-height: 45rem;
      }
    }

    .delivery-info {
      flex: 0 0 60%;
      padding: 0 0.3rem;
      display: flex;
      font-family: "ClanPro-Medium", "Segoe UI", Tahoma, Geneva, Verdana,
        sans-serif;
      flex-direction: column;
      color: white;
      width: 60%;
      align-items: flex-start;
      font-weight: 900;
    }

    @media screen and (max-width: 575px) {
      .delivery-info {
        width: 100%;
        padding: 0 0.5rem;
        align-items: flex-start;
        font-size: 14px;
        text-align: center;
      }
    }

    .delivery-heading {
      letter-spacing: 0.08em;
      font-size: 1.8em;
      margin-top: 1rem;
      margin-bottom: 2.3rem;
    }

    @media screen and (max-width: 768px) {
      .delivery-heading {
        font-size: 1.6em;
        margin: 0;
        margin-bottom: 1.2rem;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-heading {
        font-size: 17px;
        font-weight: 900;
        width: 100%;
      }
    }

    .delivery-text {
      font-weight: 400;
      font-size: 1em;
      margin-bottom: 0.6rem;
      letter-spacing: 0.032em;
    }

    @media screen and (max-width: 575px) {
      .delivery-text {
        margin-bottom: 1rem;
        font-size: 12px;
        width: 100%;
      }
    }

    .delivery-image {
      position: absolute;
      top: 50%;
      right: 15%;
      transform: translate(20%, -50%);
      z-index: 5;
      width: 36%;
      margin: 0 auto;
    }

    .delivery-image > img {
      width: 100%;
    }

    @media screen and (max-width: 575px) {
      .delivery-image {
        width: 65%;
        margin-top: 0.5rem;
        transform: unset;
        position: static;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-button {
        margin-top: 1rem;
        text-align: center;
      }
    }

    .delivery-highlight {
      font-weight: 900;
    }
  }

  @media screen and (max-width: 768px) {
    .delivery-heading {
      font-size: 1.6em;
      margin: 0;
      margin-bottom: 1.2rem;
    }
  }

  .delivery-text {
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 0.6rem;
    letter-spacing: 0.032em;
  }

  @media screen and (max-width: 575px) {
    .delivery-text {
      margin-bottom: 1rem;
      font-size: 12px;
      width: 100%;
    }
  }

  .delivery-image {
    position: absolute;
    top: 50%;
    right: 15%;
    transform: translate(20%, -50%);
    z-index: 5;
    width: 36%;
    margin: 0 auto;
  }

  .delivery-image > img {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    .delivery-image {
      width: 65%;
      margin-top: 0.5rem;
      transform: unset;
      position: static;
    }
  }

  @media screen and (max-width: 575px) {
    .delivery-button {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .delivery-highlight {
    font-weight: 900;
  }

  .delivery-item--1 {
    padding-top: 28%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.delivery-item--2 {
  padding: 2rem 9%;
  background: #88348f;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .delivery-item--2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.delivery-item--3 {
  background-color: #1885b0;
  padding: 1.2rem 9%;
}

.delivery-item--3 > .delivery-how--heading {
  color: white;
  font-weight: 900;
  font: 1.7em;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 575px) {
  .delivery-item--3 > .delivery-how--heading {
    font-size: 14px;
  }
}

.delivery-item--3 > .delivery-how--list {
  padding-top: 1rem;
  -moz-column-count: 2;
  -moz-column-rule: 2px solid white;
  -webkit-column-count: 2;
  -webkit-column-rule: 2px solid white;
  column-count: 2;
  -webkit-column-gap: 4rem;
  -moz-column-gap: 4rem;
  column-gap: 4rem;
  font-size: 1.24em;
  font-weight: 400;
  color: white;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .delivery-item--3 > .delivery-how--list {
    font-size: 1em;
  }
}
.shop-direct {
  display: none;
}
@media screen and (max-width: 575px) {
  .delivery-item--3 > .delivery-how--list {
    -moz-column-count: 1;
    -moz-column-gap: 0rem;
    -moz-column-rule: none;
    font-size: 14px;
    -webkit-column-count: 1;
    -webkit-column-rule: none;
    -webkit-column-gap: 0rem;
    column-count: 1;
    column-gap: 0rem;
  }
}

@media screen and (max-width: 575px) {
  .delivery-item {
    padding: 2rem 0.5rem;
    min-height: 35rem;
  }
  .delivery-info > h2 {
    font-size: 21px;
    text-align: center;
    width: 100%;
  }
  .delivery-info > h5 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  .delivery-image {
    max-width: 40%;
  }
  .shop-direct {
    display: inline;
  }

  .banner-content {
    display: none;
  }
}

.delivery-info {
  flex: 0 0 60%;
  padding: 0 0.3rem;
  display: flex;
  font-family: "ClanPro-Medium", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  flex-direction: column;
  color: white;
  width: 60%;
  align-items: flex-start;
  font-weight: 900;
}

@media screen and (max-width: 575px) {
  .delivery-info {
    width: 100%;
    padding: 0 0.5rem;
    align-items: flex-start;
    font-size: 14px;
    text-align: center;
  }
  .delivery-info > h5 {
    text-align: center;
    width: 100%;
  }
}

.delivery-heading {
  letter-spacing: 0.08em;
  font-size: 1.8em;
  margin-top: 1rem;
  margin-bottom: 2.3rem;
}

@media screen and (max-width: 768px) {
  .delivery-heading {
    font-size: 1.6em;
    margin: 0;
    margin-bottom: 1.2rem;
  }
}

@media screen and (max-width: 575px) {
  .delivery-heading {
    font-size: 17px;
    font-weight: 900;
    width: 100%;
  }
}

.delivery-text {
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 0.6rem;
  letter-spacing: 0.032em;
}

@media screen and (max-width: 575px) {
  .delivery-text {
    margin-bottom: 1rem;
    font-size: 12px;
    width: 100%;
  }
}

.delivery-image {
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translate(20%, -50%);
  z-index: 5;
  width: 28%;
  margin: 0 auto;
}

.delivery-image > img {
  width: 100%;
}

@media screen and (max-width: 575px) {
  .delivery-image {
    width: 65%;
    margin-top: 0.5rem;
    transform: unset;
    position: static;
  }
  .show-mobile {
    display: block;
  }
}

@media screen and (max-width: 575px) {
  .delivery-button {
    margin-top: 1rem;
    text-align: center;
  }
  .carousel-mobile-image {
    width: 100%;
    object-fit: cover;
  }
}

.delivery-highlight {
  font-weight: 900;
}

/*search box css start here*/
.search-sec {
  padding: 2rem;
  top: -500px;
}
.search-slt {
  display: block;
  width: 100%;
  /* font-size: 0.875rem; */
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  /* height: calc(3rem + 2px) !important; */
  border-radius: 0;
}
.wrn-btn {
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  height: 100% !important;
  border-radius: 0;
  color: black;
  border: none;
  background-color: #f9a51e;
}
@media (min-width: 992px) {
  .search-sec {
    /* position: relative; */
    margin-top: -100px;
    z-index: 10;
    background: black;
  }

  .slider-image {
    height: 600px !important;
  }
}

@media (max-width: 992px) {
  .search-sec {
    background: black;
  }

  .slider-image {
    height: 600px !important;
  }
}
.slider-image {
  height: 600px !important;
}

@media (max-width: 600px) {
  .slider-image {
    height: 100% !important;
  }
}

/* @import url('/css/common12d2.css'); */
.category-container {
  display: flex;
}

.mobile-categories {
  display: none;
}
@media screen and (max-width: 575px) {
  .mobile-categories {
    display: inline-flex;
    overflow-x: scroll;
    width: 100%;
  }

  .desktop-categories {
    /*display: none; */
  }
}
@media screen and (max-width: 575px) {
  .category-container {
    /* overflow-x: scroll;
    touch-action: pan-x; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.category-item {
  flex: 1;
  padding: 6rem 3;
  border: 1px solid rgba(38, 38, 38, 0.132);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 575px) {
  .category-item {
    min-width: 12rem;
    padding: 10px 0;
  }
}

.category-item:hover {
  cursor: pointer;
}

.category-item:hover > * {
  color: #757575;
  -webkit-text-stroke: 0.5px #f79b07;
}

.category-title {
  margin: 0;
  font-size: 2rem;
  color: #747474;
  text-shadow: 1px 4px 6px #def, 0 0 0 #000, 1px 4px 6px #def;
  text-align: center;
}

.category-icon {
  width: 50px;
  height: 54px;
}

@media screen and (max-width: 575px) {
  .category-icon {
    width: 30px;
    height: 34px;
  }
  .category-title {
    font-size: 10px;
  }
  .col-cat4 {
    padding: 0;
  }
  .ps-search--mobile {
    padding: 1rem 2rem 2rem 2rem;
  }
}
</style>