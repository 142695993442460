<template>
    <div class="ps-form--quick-search" style="margin-top: 5px">
      <div class="stretch-container" style="/*display: none*/">
        <form class="row add-some-padding">
          <div class="col-3 col-lg-3 col-md-3 col-sm-3 p-1">
            <div class="form-group--icon">
             
                <!-- <i class="icon-chevron-down"></i> -->
                <select v-model="searchType" class="form-control">
                <!-- <option class="level-0" value="product">Product Name</option> 
                <option class="level-0" value="shop">Shop Name</option>  -->
                <option class="level-0" value="shop-location" selected>Locations</option>
                </select>
            </div>
          </div>
          <div class="col-7 col-lg-8 col-md-7 col-sm-7 p-1">
            <!-- <input
              class="form-control"
              type="text"
              v-model="searchQuery"
              v-if="searchType === 'shop'"
              placeholder="Search for shop"
              id="search_shop"
            /> -->
            <input
              class="form-control"
              type="text" 
              v-model="searchQuery"
              v-if="searchType === 'shop-location'"
              placeholder="Type Merchant/Runner city"
              id="search_location"
            />
            <!-- <input
              class="form-control"
              type="text"
              v-model="searchQuery"
              v-if="searchType === 'product'"
              placeholder="I am looking for..."
              id="search_product"
            /> -->
          </div>
          <div class="col-2 col-lg-1 col-md-2 col-sm-2 p-1">
            <button class="search-button" @click.prevent="search()">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="15.000000pt"
                height="15.000000pt"
                viewBox="0 0 225.000000 225.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                  fill="#000"
                  stroke="none"
                >
                  <path
                    d="M787 2184 c-273 -49 -519 -236 -642 -489 -59 -120 -85 -224 -92 -365 -26 -569 481 -1019 1047 -930 128 21 284 84 383 157 l49 36 272 -272 c245 -245 275 -271 305 -271 45 0 91 46 91 91 0 30 -26 60 -271 305 l-272 272 35 49 c45 61 94 159 124 245 35 103 47 204 41 331 -26 546 -527 939 -1070 841z m326 -180 c40 -9 115 -36 167 -61 81 -39 107 -59 185 -137 149 -150 215 -306 215 -511 0 -276 -147 -518 -391 -645 -108 -56 -208 -80 -334 -80 -205 0 -361 66 -511 215 -78 78 -98 104 -137 185 -61 128 -81 222 -75 359 9 180 71 328 193 459 175 189 438 271 688 216z"
                  />
                </g>
              </svg>
            </button>
          </div>
        </form>
      </div>
      <div class="stretch-container" style="display: none">
        <form
          class="row add-some-padding"
          action="search-shops-result/harare/location"
          id="search_for_merchant"
        >
          <div class="col-12 ls-center" style="display: none">
            <p>Search for Merchant</p>
          </div>
  
          <div
            class="col-10 col-lg-3 col-md-3 col-sm-12 p-0"
            style="display: none"
          >
            <input
              id="store_loc_title"
              type="text"
              class="form-control text-center"
              disabled
              value="Store Locator Zim Town /City"
            />
          </div>
          <div class="col-10 col-lg-11 col-md-10 col-sm-10 p-0">
            <input
              type="text"
              class="form-control search-slt search-styling"
              v-model="searchQuery"
              style="text-align: center"
              placeholder="Find Merchants or Runners by Town/City"
            />
          </div>
          <div class="col-2 col-lg-1 col-md-2 col-sm-2 p-0">
            <button
              id="search-for-merchant"
              type="button"
              class="btn btn-danger wrn-btn"
              @click="search2()"
            >
              <!--Search for Merchant-->
  
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="15.000000pt"
                height="15.000000pt"
                viewBox="0 0 225.000000 225.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                  fill="#000"
                  stroke="none"
                >
                  <path
                    d="M787 2184 c-273 -49 -519 -236 -642 -489 -59 -120 -85 -224 -92 -365 -26 -569 481 -1019 1047 -930 128 21 284 84 383 157 l49 36 272 -272 c245 -245 275 -271 305 -271 45 0 91 46 91 91 0 30 -26 60 -271 305 l-272 272 35 49 c45 61 94 159 124 245 35 103 47 204 41 331 -26 546 -527 939 -1070 841z m326 -180 c40 -9 115 -36 167 -61 81 -39 107 -59 185 -137 149 -150 215 -306 215 -511 0 -276 -147 -518 -391 -645 -108 -56 -208 -80 -334 -80 -205 0 -361 66 -511 215 -78 78 -98 104 -137 185 -61 128 -81 222 -75 359 9 180 71 328 193 459 175 189 438 271 688 216z"
                  />
                </g>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HeaderSearch2", 
    data() {
      return {
        searchType: "shop-location",
        searchMethod: "Shop Location",
        searchQuery: "",
        currentPlace: {},
      };
    },
    methods: {
      search() {
        if (!this.searchQuery) {
          this.$toasted.error("Please enter a search term.");
        } else if (this.searchType === "product") {
          location.replace(`/search-shops-result/${this.searchQuery}/product`);
        } else if (this.searchType === "shop-ccategory") {
          location.replace(`/search-shops-result/${this.searchQuery}/category`);
        } else if (this.searchType === "name-of-shop") {
          location.replace(`/search-shops-result/${this.searchQuery}/shop`);
        } else {
          this.$router.push({
            name: "products_result",
            params: { query: this.searchQuery },
          });
  
          location.replace(`/search-products-result/${this.searchQuery}`);
        }
      },
      search2() {
        if (this.searchQuery == "") {
          alert("please enter a store location to search first");
        } else if (this.searchMethod === "Shop Location") {
          location.replace(`/search-shops-result/${this.searchQuery}/location`);
        } else if (this.searchMethod === "Shop Category") {
          location.replace(`/search-shops-result/${this.searchQuery}/category`);
        } else if (this.searchMethod === "Shop") {
          location.replace(`/search-shops-result/${this.searchQuery}/shop`);
        } else {
          this.$router.push({
            name: "products_result",
            params: { query: this.searchQuery },
          });
  
          location.replace(`/search-products-result/${this.searchQuery}`);
        }
      },
    },
  };
  </script>
  
  <style scoped></style>